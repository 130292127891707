import { Button } from '@tmap/mmm-style-guide/src/Button';
import { IconButton } from '@tmap/mmm-style-guide/src/IconButton';

const ResponsiveIconButton = (props) => {
  const {
    breakpoint = 'sm',
    icon,
    sx = {},
    ...buttonProps
  } = props;
  return <>
    <Button {...buttonProps} sx={{ ...sx, display: breakpoint === 'disabled' ? undefined : { xs: 'none', [breakpoint]: 'inline-flex' } }}>{props.children}</Button>
    <IconButton {...buttonProps} sx={{ ...sx, display: breakpoint === 'disabled' ? 'none' : { xs: 'inline-flex', [breakpoint]: 'none' } }}>{icon}</IconButton>
  </>
}

export default ResponsiveIconButton;