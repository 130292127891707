import React from 'react';
import { useRouter } from 'next/router';
import { connect } from 'react-redux';

import { Box } from '@tmap/mmm-style-guide/src/Box';
import { Favorite, FavoriteBorder } from '@tmap/mmm-style-guide/src/Icon';
import { useTheme } from '@tmap/mmm-style-guide/src/Theming';
import nestGet from '@tmap/mmm-core/nestGet';
import stopEvent from '@tmap/mmm-style-guide/src/util/stopEvent';
import { toKey, types } from '@tmap/mmm-core/entityKey';

import {
  addUserFavorite as addUserFavoriteAction,
  removeUserFavorite as removeUserFavoriteAction,
} from '../actions/user';
import ResponsiveIconButton from './responsiveIconButton';
import useRequireAuth from '../hooks/useRequireAuth';

function FavoriteButton(props) {
  const {
    authStore,
    entity,
    entityKey,
    isFavorite,
    variant = 'text',
    color = 'favorite',
    fontSize = 'medium',
    dim = false,
    removeUserFavorite,
    addUserFavorite,
    Button = ResponsiveIconButton,
    children,
    ...buttonProps
  } = props;
  const theme = useTheme();
  const router = useRouter();
  const { requireAuth } = useRequireAuth();
  let url;
  switch (entity._type) {
    case types.INCENTIVE:
      url = `/get-paid/${entity.slug.current}`;
      break;
    case types.COMMUNITY:
      url = `/moving-living/${entity.slug.current}`;
      break;
    default:
      url = router.asPath;
  }

  const handleFavorite = (e) => {
    stopEvent(e);
    requireAuth(() => {
      if (isFavorite) {
        removeUserFavorite(entityKey);
      } else {
        addUserFavorite(entityKey);
      }
    }, { returnTo: `/favorites/continue?id=${entityKey}&url=${url}` });
  };

  const favoritedIcon = (
    <Favorite fontSize={fontSize} sx={{ color: theme.palette.favorite.main }} />
  );
  const unfavoritedIcon = dim ? (
    <Favorite fontSize={fontSize} sx={{ strokeWidth: '1px', stroke: theme.palette.common.white, fill: '#1F1F2366' }} />
  ) : (
    <FavoriteBorder fontSize={fontSize} />
  );

  return (
    <Button
      icon={isFavorite ? favoritedIcon : unfavoritedIcon}
      variant={variant}
      color={color}
      onClick={handleFavorite}
      {...buttonProps}
    >
      {isFavorite ? favoritedIcon : unfavoritedIcon}
      {children && (
        <Box sx={{ marginLeft: 1 }}>{children}</Box>
      )}
    </Button>
  );
}

function mapStateToProps(state, ownProps) {
  // making the component api backwards compatible
  const entity = ownProps.incentive ? ownProps.incentive : ownProps.entity;
  const entityKey = toKey(entity._type, entity._id);
  return {
    authStore: nestGet(state, ['authStore']),
    isFavorite: (nestGet(state, ['users', 'favorites']) || []).includes(entityKey),
    entity,
    entityKey,
  };
}
export default connect(mapStateToProps, {
  addUserFavorite: addUserFavoriteAction,
  removeUserFavorite: removeUserFavoriteAction,
})(FavoriteButton);
